<template>
<div class="bg-white p-2 rounded-md mt-2">
  <DataTable :value="derechosPeticion.rows"
             class="p-datatable-customers p-datatable-sm text-sm"
             showGridlines
             dataKey="id"
             v-model:filters="filters1"
             filterDisplay="menu"
             responsiveLayout="scroll"
             removableSort
  >
    <template #empty>
      No hay datos para mostrar
    </template>

    <Column headerStyle="width: 3rem" style="text-align: center; justify-content: center;" field="fullname" header="Id">
      <template #body="{data}">
        <Button :label="`${data.id}`"
            class="p-button-link text-black"
            style="font-size: 0.75rem"
            @click="ver(data.id)"
        />
      </template>
    </Column>

    <Column field="asunto" header="Solicitante" style="min-width:10rem">
      <template #body="{data}">
        {{ data.accionante.fullname }}
      </template>
    </Column>

    <!-- <Column field="asunto" header="Correo" style="min-width:10rem">
      <template #body="{data}">
        {{ data.accionante.correo }}
      </template>
    </Column> -->

    <!-- <Column field="asunto" header="Telefono" style="min-width:10rem">
      <template #body="{data}">
        {{ data.accionante.telefono }}
      </template>
    </Column> -->

    <Column field="asunto" header="Asunto" style="min-width:10rem">
      <template #body="{data}">
        {{ data.asunto }}
      </template>
    </Column>

    <Column
      header="Categoria"
      field="tipo"
      style="min-width:10rem"
    >
      <template #body="{data}">
        {{ data.tipo === 1 ? 'Derecho de petición' : (data.tipo === 2 ? 'Requerimiento' : 'PQR') }}
      </template>
      <template #filter="{filterModel}">
        <Dropdown v-model="filterModel.value" :options="categorias" optionLabel="name" class="p-column-filter" optionValue="code" placeholder="Categoria" />
      </template>
    </Column>

    <Column field="fechaNotificacion" header="Fecha Notificacion" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaNotificacion, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <input v-model="filterModel.value" type="date" class="p-inputtext" placeholder="Fecha Notificacion" />
      </template>
    </Column>

    <Column field="fechaVencimiento" header="Fecha Vencimiento" style="min-width:10rem">
      <template #body="{data}">
        {{ $h.formatDate(data.fechaVencimiento, 'YYYY-MM-DD HH:mm:ss') }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <input v-model="filterModel.value" type="date" class="p-inputtext" placeholder="Fecha Vencimiento" />
      </template>
    </Column>

    <Column field="createdBy" header="Creado Por" style="min-width:10rem" :showFilterMatchModes="false">
      <template #body="{data}">
        {{ data.createdByName }}
      </template>
      <template #filter="{filterModel}">
        <div class="mb-3 font-bold">Seleccione</div>
        <Dropdown v-model="filterModel.value" :options="usuarios" optionLabel="fullName" optionValue="id" placeholder="Cualquiera" :filter="true" />
      </template>
    </Column>
    <Column header="Estado"
      field="estado"
      style="min-width:10rem; text-align: center"
    >
      <template #body="{data}">
        <Button
          type="button"
          v-if="data.respuestas.length === 0"
          class="font-medium p-1.5 text-white w-3/4 text-center rounded-md border-0"
          :class="indicadorEstadoColor(data.fechaVencimiento, data.respuesta)"
          :label="indicadorEstadoNombre(data.fechaVencimiento, data.respuesta)"
          v-tooltip.bottom="{value:
          `${dayjs(data.fechaVencimiento) > dayjs() ? 'Vence' : 'Vencido'} ${dayjs(data.fechaVencimiento).locale('es').fromNow()}
          `
          }" />
        <Button
          type="button"
          label="Respondida"
          v-else
          class="font-medium p-1.5 text-white w-3/4 text-center rounded-md border-0"
          v-tooltip.bottom="{value: 'Respondida' }"
        />
      </template>
    </Column>
    <Column header="Acciones" headerStyle="width: 3rem">
      <template #body="{data}">
        <div class="flex justify-center">
          <!-- <Button icon="pi pi-file-pdf" @click="openModal(data.id)" class="p-button-rounded p-button-info" /> -->
          <!-- <Button icon="pi pi-eye" class="p-button-rounded p-button-help" @click="ver(data.id)" /> -->
          <Button icon="pi pi-pencil" class="p-button-rounded p-button-warning" @click="onEditar(data)" />
          <!-- <Button icon="pi pi-trash" class="p-button-rounded p-button-danger" @click="remove(data)" /> -->
        </div>
      </template>
    </Column>
  </DataTable>
  <Paginator v-model:first="offset"
    :rows="limit"
    :totalRecords="totalItemsCount"
    :rowsPerPageOptions="[2,10,20,30]"
    @page="onPage($event)"
  />
</div>
</template>

<script>
import derechosStore from '../../../../../store/derechosPeticion.store'
import { computed, onMounted, ref, watch } from 'vue'
import { FilterMatchMode, FilterOperator } from 'primevue/api'
import Swal from 'sweetalert2'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { useRouter } from 'vue-router'
import PdfService from '@/apps/pharmasan/juridica/procesosJuridicos/services/pdf.service'
import UserSerivce from '@/apps/pharmasan/juridica/procesosJuridicos/services/usuarios.service'
dayjs.extend(duration)
dayjs.extend(relativeTime)

export default {
  name: 'TablaDerechosDePeticion',
  setup () {
    onMounted(async () => {
      await derechosStore.dispatch('getPaginate', params.value)
      await _UserService.getAll().then(({ data }) => {
        usuarios.value = data
      })
      dayjs.extend(duration)
      dayjs.extend(relativeTime)
      dayjs().locale('es')
    })

    const router = useRouter()
    const _PdfService = new PdfService()
    const _UserService = new UserSerivce()
    const derechosPeticion = computed(() => derechosStore.getters.derechosPeticion)
    const usuarios = ref([])
    const displayModal = ref(false)
    const offset = ref(0)
    const page = ref(0)
    const limit = ref(10)
    const adjuntos = ref([])
    const categorias = [
      { name: 'Derecho de petición', code: 1 },
      { name: 'Requerimiento', code: 2 },
      { name: 'PQR', code: 3 }
    ]

    const derecho = computed(() => derechosStore.getters._derecho)
    const totalItemsCount = computed(
      () => derechosStore.getters.derechosPeticion.count
    )
    const params = computed(() => {
      return {
        offset: offset.value,
        page: page.value,
        limit: limit.value
      }
    })

    const verPdf = (path) => {
      _PdfService.ver({ path })
    }

    const openModal = (id) => {
      displayModal.value = true
      derechosStore.dispatch('find', id)
      adjuntos.value = derecho.value.paths
    }

    const closeModal = () => {
      displayModal.value = false
    }

    const indicadorEstadoColor = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'atiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'proximo-vencer'
        } else {
          return 'vencidos'
        }
      }
    }

    const indicadorEstadoNombre = (fechaVencimiento, respuesta = false) => {
      const inicio = dayjs()
      const fin = dayjs(`${fechaVencimiento}`)
      if (respuesta) return 'Respondida'
      if (fin.diff(inicio, 'day') >= 1) {
        return 'A tiempo'
      } else if (fin.diff(inicio, 'day') < 1) {
        if (fin.diff(inicio, 'second') > 1) {
          return 'A vencer'
        } else {
          return 'Vencido'
        }
      }
    }

    const ver = (id) => {
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-administrativos.ver', params: { id: id } })
    }

    const filters1 = ref({
      fullname: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      createdBy: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      tipo: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      fechaNotificacion: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      },
      fechaVencimiento: {
        operator: FilterOperator.AND,
        constraints: [
          {
            value: null,
            matchMode: FilterMatchMode.STARTS_WITH
          }
        ]
      }
    })

    const clearFilter1 = () => {
      filters1.value = {
        fullname: {
          operator: FilterOperator.AND,
          constraints: [
            {
              value: null,
              matchMode: FilterMatchMode.STARTS_WITH
            }
          ]
        }
      }
    }
    const onEditar = (data) => {
      router.push({ name: 'pharmasan.administrativa.juridica.procesos-administrativos.formulario-editar', params: { id: data.id } })
    }
    const onPage = ({ first, page, pageCount, rows }) => {
      limit.value = rows
      derechosStore.dispatch('getPaginate', params.value)
    }

    const remove = ({ id }) => {
      Swal.fire({
        title: 'Esta seguro?',
        text: 'Si se elimina este registro no se puede recuperar',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, eliminar!'
      }).then((result) => {
        if (result.isConfirmed) {
          derechosStore.dispatch('removeDerecho', id).then(async () => {
            await derechosStore.dispatch('getPaginate', params.value)
            await Swal.fire(
              'Eliminado!',
              'El fallo fue eliminada con exito.',
              'success'
            )
          })
        }
      })
    }

    watch(filters1, (a, b) => {
      offset.value = 0
      page.value = 0
      limit.value = 10
      derechosStore.dispatch('getPaginate', params.value)
      derechosStore.dispatch('getPaginate', params.value)
    })

    return {
      derechosPeticion,
      filters1,
      clearFilter1,
      onPage,
      remove,
      onEditar,
      verPdf,
      usuarios,
      ver,
      openModal,
      closeModal,
      displayModal,
      adjuntos,
      totalItemsCount,
      offset,
      page,
      limit,
      indicadorEstadoColor,
      indicadorEstadoNombre,
      dayjs,
      relativeTime,
      categorias
    }
  }
}
</script>

<style scoped>
  ::v-deep(.pi) {
    font-size: 0.8rem;
}
  ::v-deep(.p-column-header-content) {
    justify-content: center;
}
.respondida {
    background-color: #1149d7 !important;
    color: white;
  }
.atiempo {
  background-color: #58AB30 !important;
  color: white;
}
.proximo-vencer {
  background-color: #FA901B !important;
}
.vencidos {
  background-color: #FA2311 !important;
}
</style>
